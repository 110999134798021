import axios from "axios";
import { ACTIONURL } from "./apiurls";


export const registration = async (userData) => {
    try {

        return await axios.post(`${ACTIONURL}/dashregisterUser`, userData)

    } catch (error) {
        return error
    }
}

// Add New Free User
export const addNewFreeUser = async (newUserData) => {
    try {

        return await axios.post(`${ACTIONURL}/addNewFreeUser`, newUserData)

    } catch (error) {
        return error
    }
}

export const addPromotionFun = async (promotionData) => {
    try {

        return await axios.post(`${ACTIONURL}/dashAddPromotion`, promotionData)

    } catch (error) {
        return error
    }
}

export const registerCompany = async (promotionData) => {
    try {

        return await axios.post(`${ACTIONURL}/registerCompany`, promotionData)

    } catch (error) {
        return error
    }
}

export const editPromotionFun = async (promotionData) => {
    try {

        return await axios.post(`${ACTIONURL}/dashEditPromotion`, promotionData)

    } catch (error) {
        return error
    }
}

export const editCompanyFun = async (promotionData) => {
    try {

        return await axios.post(`${ACTIONURL}/dashEditCompany`, promotionData)

    } catch (error) {
        return error
    }
}

export const login = async (userData) => {
    try {

        return await axios.post(`${ACTIONURL}/dashloginUser`, userData)

    } catch (error) {
        return error.response
    }
}


export const getAllUsers = async (pageNumber, searchTerm, promo) => {
    try {

        // return await axios.get(`http://127.0.0.1:8000/api/allUsers?page=${pageNumber}&searchTerm=${searchTerm}`)
        // return await axios.get(`http://127.0.0.1:8000/api/v1/allUsers?page=${pageNumber}&searchTerm=${searchTerm}`)
        return await axios.get(`${ACTIONURL}/allUsers?page=${pageNumber}&searchTerm=${searchTerm}&promo=${promo}`)

    } catch (error) {
        return error.response
    }
}

export const getAllUsersDash = async () => {
    try {

        return await axios.get(`${ACTIONURL}/allUsersDash`)

    } catch (error) {
        return error.response
    }
}

export const saveSecretCode = async (secretCode) => {
    try {

        const data = {
            secretCode: secretCode
        }

        return await axios.post(`${ACTIONURL}/saveSecretCodeCall`, data)

    } catch (error) {
        return error.response
    }
}

export const savePromoCode = async (promoCode) => {
    try {

        const data = {
            promoCode: promoCode
        }

        return await axios.post(`${ACTIONURL}/savePromoCodeCall`, data)

    } catch (error) {
        return error.response
    }
}


export const addProfessionFun = async (trade) => {
    try {

        const data = {
            trade: trade
        }

        return await axios.post(`${ACTIONURL}/addProfession`, data)

    } catch (error) {
        return error.response
    }
}

export const sendNotificationFun = async (message) => {
    try {

        const data = {
            message: message
        }

        return await axios.post(`${ACTIONURL}/sendNotificationCall`, data)

    } catch (error) {
        return error.response
    }
}

export const updateProfessionFun = async (trade, id) => {
    try {

        const data = {
            trade: trade,
            id: id
        }

        return await axios.post(`${ACTIONURL}/updateProfession`, data)

    } catch (error) {
        return error.response
    }
}

export const getAllProfessions = async (pageNumber, searchTerm) => {
    try {
        return await axios.get(`${ACTIONURL}/getAllProfessionsCall?page=${pageNumber}&searchTerm=${searchTerm}`)
    } catch (error) {
        return error.response
    }
}

export const getAllPromocodes = async (pageNumber, searchTerm) => {
    try {
        return await axios.get(`${ACTIONURL}/getAllPromocodesCall?page=${pageNumber}&searchTerm=${searchTerm}`)
    } catch (error) {
        return error.response
    }
}

export const getAllDashboardUsers = async (pageNumber, searchTerm, sessionUser) => {
    try {

        return await axios.get(`${ACTIONURL}/allDashboardUsers?page=${pageNumber}&searchTerm=${searchTerm}&id=${sessionUser}`)

    } catch (error) {
        return error.response
    }
}


export const deleteFunction = async (id) => {
    try {

        return await axios.get(`${ACTIONURL}/deleteUser/${id}`)

    } catch (error) {
        return error.response
    }
}

export const deleteAppUserFunction = async (id) => {
    try {

        return await axios.get(`${ACTIONURL}/deleteAppUser/${id}`)

    } catch (error) {
        return error.response
    }
}

export const deleteJobFunction = async (id) => {
    try {

        return await axios.get(`${ACTIONURL}/deleteJob/${id}`)

    } catch (error) {
        return error.response
    }
}

export const deleteProfessionFunction = async (id) => {
    try {

        return await axios.get(`${ACTIONURL}/deleteProfessionFunction/${id}`)

    } catch (error) {
        return error.response
    }
}

export const deletePromocodeFunction = async (id) => {
    try {

        return await axios.get(`${ACTIONURL}/deletePromocodeFunction/${id}`)

    } catch (error) {
        return error.response
    }
}


export const deletePromotion = async (id) => {
    try {

        return await axios.get(`${ACTIONURL}/deletePromotion/${id}`)

    } catch (error) {
        return error.response
    }
}

export const deleteCompany = async (id) => {
    try {

        return await axios.get(`${ACTIONURL}/deleteCompany/${id}`)

    } catch (error) {
        return error.response
    }
}


export const changeStatusFun = async (id, status, admin = 0) => {
    try {

        const data = {
            id: id,
            status: status,
            admin: admin
        }

        return await axios.post(`${ACTIONURL}/status`, data)

    } catch (error) {
        return error.response
    }
}

export const changeRoleFun = async (id, role) => {
    try {

        const data = {
            id: id,
            role: role
        }

        return await axios.post(`${ACTIONURL}/role`, data)

    } catch (error) {
        return error.response
    }
}

export const resetPasswordFun = async (oldPassword, newPassword, id) => {
    try {

        const data = {
            oldPassword: oldPassword,
            newPassword: newPassword,
            id: id
        }

        return await axios.post(`${ACTIONURL}/resetPasswordDash`, data)

    } catch (error) {
        return error.response
    }
}

export const resetInfoFun = async (formData) => {
    try {

        return await axios.post(`${ACTIONURL}/resetInfo`, formData)

    } catch (error) {
        return error.response
    }
}

export const getAllPostsDataFun = async (id) => {
    try {
        const data = {
            id: id
        }
        return await axios.post(`${ACTIONURL}/getAllPostsData`, data)

    } catch (error) {
        return error.response
    }
}

export const deletePost = async (id) => {
    try {

        return await axios.get(`${ACTIONURL}/deletePost/${id}`)

    } catch (error) {
        return error.response
    }
}

export const resetAppUser = async (formData) => {
    try {

        return await axios.post(`${ACTIONURL}/resetAppUserProfile`, formData)

    } catch (error) {
        return error.response
    }
}



export const getAllJobs = async (pageNumber, searchTerm, startDate, endDate) => {

    startDate = startDate ? startDate.toISOString().slice(0, 10).replace('T', ' ') : '';
    endDate = endDate ? endDate.toISOString().slice(0, 10).replace('T', ' ') : '';


    console.log("getAllJobs State Date:" + startDate);
    console.log("getAllJobs  End  Date:" + endDate);

    try {
        const url = `${ACTIONURL}/allJobs?page=${pageNumber}&searchTerm=${searchTerm}&startDate=${startDate}&endDate=${endDate}`;

        //console.log('getAllJobsURL: ' + url)

        return await axios.get(url)

    } catch (error) {
        return error.response
    }
}


export const fetchPostDetailsById = async (postId) => {
    try {
        const response = await fetch(`${ACTIONURL}/postdetailsingle${postId}`);
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching post details:', error);
        throw error;
    }
}

export const changeJobStatusFunction = async (id, status, admin = 0) => {
    try {

        const data = {
            id: id,
            status: status,
            admin: admin
        }

        return await axios.post(`${ACTIONURL}/jobStatus`, data)

    } catch (error) {
        return error.response
    }
}

export const getAllSubscriptions = async (pageNumber, searchTerm, sessionUser, startDate, endDate) => {

    startDate = startDate ? startDate.toISOString().slice(0, 10).replace('T', ' ') : '';
    endDate = endDate ? endDate.toISOString().slice(0, 10).replace('T', ' ') : '';

    try {

        const url = `${ACTIONURL}/allSubscriptions?page=${pageNumber}&searchTerm=${searchTerm}&id=${sessionUser}&startDate=${startDate}&endDate=${endDate}`;

        console.log("getAllSubscriptions URL: " + url)

        return await axios.get(url)

    } catch (error) {

        return error.response
    }
}

export const getAllPaymentsLogs = async (pageNumber, searchTerm, startDate, endDate, joining) => {
    try {
        startDate = startDate ? startDate.getFullYear() + '-' + (startDate.getMonth() + 1).toString().padStart(2, '0') + '-' + startDate.getDate().toString().padStart(2, '0') : '';
        endDate = endDate ? endDate.getFullYear() + '-' + (endDate.getMonth() + 1).toString().padStart(2, '0') + '-' + endDate.getDate().toString().padStart(2, '0') : '';

        const url = `${ACTIONURL}/getAllPaymentsLogs?page=${pageNumber}&searchTerm=${searchTerm}&startDate=${startDate}&endDate=${endDate}&joining=${joining}`;
        return await axios.get(url)

    } catch (error) {

        return error.response
    }
}


export const getAllNotifications = async (pageNumber, searchTerm, sessionUser) => {
    try {

        return await axios.get(`${ACTIONURL}/allNotifications?page=${pageNumber}&searchTerm=${searchTerm}&id=${sessionUser}`)

    } catch (error) {
        return error.response
    }
}

export const getNotificationByType = async (pageNumber, type, startDate, endDate, searchTerm, sessionUser) => {

    startDate = startDate ? startDate.toISOString().slice(0, 10).replace('T', ' ') : '';
    endDate = endDate ? endDate.toISOString().slice(0, 10).replace('T', ' ') : '';


    // console.log("pageNumber:", pageNumber);
    // console.log("type:", type);
    // console.log("startDate:", startDate);
    // console.log("endDate:", endDate);
    // console.log("searchTerm:", searchTerm);
    // console.log("sessionUser:", sessionUser);

    try {
        //return startDate;
        // Construct the URL with the required parameters
        const url = `${ACTIONURL}/getNotificationByType?page=${pageNumber}&type=${type}&startDate=${startDate}&endDate=${endDate}&searchTerm=${searchTerm}&id=${sessionUser}`;

        //console.log(url);

        // Make the GET request using axios
        return await axios.get(url);
    } catch (error) {
        // Return the error response if the request fails
        return error.response;
    }
}



export const getNotificationById = async (id) => {

    try {

        // Construct the URL with the required parameters
        const url = `${ACTIONURL}/getNotificationById?id=${id}`;
        // Make the GET request using axios
        return await axios.get(url);
    } catch (error) {
        // Return the error response if the request fails
        return error.response;
    }
}


export const saveReplyToDatabase = async (replyData) => {

    try {

        // Construct the URL with the required parameters
        const url = `${ACTIONURL}/saveReplyToDatabase`;
        console.log(url);

        const data = {
            notificationId: replyData.notificationId,
            title: replyData.title,
            description: replyData.description,
            type: replyData.type,
            cUserID: replyData.cUserID
        }

        // Make the GET request using axios
        return await axios.post(url, data);
    } catch (error) {
        // Return the error response if the request fails
        return error.response;
    }
}

// Get Promotions Data
export const getPromotionsDataFun = async (pageNumber, startDate, endDate, searchTerm) => {
    startDate = startDate ? startDate.getFullYear() + '-' + (startDate.getMonth() + 1).toString().padStart(2, '0') + '-' + startDate.getDate().toString().padStart(2, '0') : '';
    endDate = endDate ? endDate.getFullYear() + '-' + (endDate.getMonth() + 1).toString().padStart(2, '0') + '-' + endDate.getDate().toString().padStart(2, '0') : '';

    try {
        const url = `${ACTIONURL}/getPromotionData?page=${pageNumber}&startDate=${startDate}&endDate=${endDate}&searchTerm=${searchTerm}`;
        return await axios.get(url);
    } catch (error) {
        return error.response;
    }
}

// Get Companies Data
export const getCompaniesDataFun = async (pageNumber, startDate, endDate, searchTerm) => {
    startDate = startDate ? startDate.getFullYear() + '-' + (startDate.getMonth() + 1).toString().padStart(2, '0') + '-' + startDate.getDate().toString().padStart(2, '0') : '';
    endDate = endDate ? endDate.getFullYear() + '-' + (endDate.getMonth() + 1).toString().padStart(2, '0') + '-' + endDate.getDate().toString().padStart(2, '0') : '';

    try {
        const url = `${ACTIONURL}/getCompaniesData?page=${pageNumber}&startDate=${startDate}&endDate=${endDate}&searchTerm=${searchTerm}`;
        return await axios.get(url);
    } catch (error) {
        return error.response;
    }
}

export const getReportUsers = async (pageNumber, startDate, endDate, searchTerm) => {
    startDate = startDate ? startDate.getFullYear() + '-' + (startDate.getMonth() + 1).toString().padStart(2, '0') + '-' + startDate.getDate().toString().padStart(2, '0') : '';
    endDate = endDate ? endDate.getFullYear() + '-' + (endDate.getMonth() + 1).toString().padStart(2, '0') + '-' + endDate.getDate().toString().padStart(2, '0') : '';

    try {
        const url = `${ACTIONURL}/getReportUser?page=${pageNumber}&startDate=${startDate}&endDate=${endDate}&searchTerm=${searchTerm}`;
        return await axios.get(url);
    } catch (error) {
        return error.response;
    }
}

export const getReportPosts = async (pageNumber, startDate, endDate, searchTerm) => {
    startDate = startDate ? startDate.getFullYear() + '-' + (startDate.getMonth() + 1).toString().padStart(2, '0') + '-' + startDate.getDate().toString().padStart(2, '0') : '';
    endDate = endDate ? endDate.getFullYear() + '-' + (endDate.getMonth() + 1).toString().padStart(2, '0') + '-' + endDate.getDate().toString().padStart(2, '0') : '';
    try {
        const url = `${ACTIONURL}/getReportPosts?page=${pageNumber}&startDate=${startDate}&endDate=${endDate}&searchTerm=${searchTerm}`;
        return await axios.get(url);
    } catch (error) {
        return error.response;
    }
}
