import React, { useEffect } from 'react'
import {
  CAvatar,
  CBadge,
  CDropdown,
  CDropdownDivider,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react'
import {
  cilBell,
  cilCreditCard,
  cilCommentSquare,
  cilEnvelopeOpen,
  cilFile,
  cilLockLocked,
  cilSettings,
  cilTask,
  cilUser,
} from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { useSelector } from 'react-redux'
import { stateUserData, userLoginSuccess, userLogoutSuccess } from 'src/redux/reducers/userReducer'

import { NavLink, useNavigate } from 'react-router-dom'
import store from 'src/store'
import { IMAGEURL } from 'src/services/apiurls'

const AppHeaderDropdown = () => {

  const userData = useSelector(stateUserData)

  const navigate = useNavigate()

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'))
    if (user) {
      store.dispatch(userLoginSuccess(user))
    }
  }, [])


  const logout = () => {
    localStorage.removeItem('user')
    store.dispatch(userLogoutSuccess())
    navigate('/')
  }

  return (
    <CDropdown variant="nav-item">
      <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
        <CAvatar src={`${IMAGEURL}/` + userData.sessionUser.photo} size="md" style={{ alignItems: 'unset' }} />
        {/* <CAvatar src={avatar8} size="md" /> */}
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownHeader className="bg-light fw-semibold py-2">Account</CDropdownHeader>
        <CDropdownDivider />
        <CDropdownItem onClick={logout} className='logoutClass'>
          <CIcon icon={cilLockLocked} className="me-2" />
          Logout
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  )
}

export default AppHeaderDropdown
